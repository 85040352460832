import {default as React} from 'react';
import '@fontsource/lato/100.css';
import '@fontsource/lato/300.css';
import '@fontsource/lato';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';
import './src/styles/global.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const confirmation = e => {
  if(!window?.confirm(`We provide links for your convenience, but we do not review, control, or monitor the materials on any other websites. We do not control or endorse this type of information. We are not responsible for the performance of those sites or for your business dealings with them. Your use of other websites is subject to the terms of use and privacy policies of those sites and businesses.`)){
    e.preventDefault()
    return false
  }

  return true
}

export const wrapRootElement = ({ element }) => (
  <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
    { element }
  </GoogleReCaptchaProvider>
) 

export const onRouteUpdate = ({ location, prevLocation }) => {
  const anchors = document.querySelectorAll('a[target="_blank"]')
  anchors.forEach(a => {
    a.addEventListener('click', confirmation)
  })
}