import tailwindConfig from /* preval */ './resolveConfig'

const fontSizes = Object.entries(tailwindConfig.theme.fontSize).map(([key, [value]]) => ({ [key]: value })).reduce((acc, curr) => ({ ...acc, ...curr}), {})

const theme = {
  colors: {
    ...tailwindConfig.theme.colors
  },
  breakpoints: Object.values(tailwindConfig.theme.screens),
  space: {
    ...tailwindConfig.theme.spacing
  },
  fonts: {
    ...tailwindConfig.theme.fontFamily
  },
  fontSizes,
  lineHeights: {
    ...tailwindConfig.theme.lineHeight
  },
  letterSpacings: {
    ...tailwindConfig.theme.letterSpacing
  },
  shadows: {
    ...tailwindConfig.theme.boxShadow
  },
  radii: {
    ...tailwindConfig.theme.borderRadius
  }
}

export default theme