const defaultTheme = require('tailwindcss/defaultTheme')
const { [`2xl`]: double, ...screens } = defaultTheme.screens 

module.exports = {
  purge: {
    content: ['./src/**/*.{js,jsx,ts,tsx}'],
    options: {
      safelist: {
        standard: [/^md:w-+/],
      }
    }
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      colors: {
        primary: {
          DEFAULT: '#1D3E4C',
          __default: '#1D3E4C'
        },
        secondary: {
          DEFAULT: '#91C96E',
          __default: '#91C96E'
        },
        sand: {
          100: '#f4f3f1',
          200: '#e9e7e3',
          300: '#dfdcd5',
          400: '#d4d0c7',
          DEFAULT: '#C9C4B9',
          __default: '#C9C4B9',
          600: '#a19d94',
          700: '#79766f',
          800: '#504e4a',
          900: '#282725'
        }
      },
    },
    screens: {
      ...screens
    },
    fontFamily: {
      body: ['Lato', ...defaultTheme.fontFamily.sans],
      heading: ['minion-pro', ...defaultTheme.fontFamily.serif]
    }
  },
  variants: {
    extend: {},
  },
  plugins: [
    require('@tailwindcss/forms'),
  ],
}
